import React from "react";

import { motion } from "framer-motion";

import "./HomeCommonContainer.scss";

import parallexImage from "../assets/parallex/web-for-parallex-1.png";
import homeImage from "../assets/parallex/work.png";

import flower1 from "../assets/parallex/2.png";
import flower2 from "../assets/parallex/3.png";

const CommonContainer = () => {
  return (
    <div className="common-container-layout">
      <div className="overlay-container"></div>

      <div className="home-image">
        <img src={homeImage} alt="" />
      </div>

      <div className="flower1-parallex-container">
        <img src={flower1} alt="" />
      </div>

      <div className="flower2-parallex-container">
        <img src={flower2} alt="" />
      </div>

      <motion.div
        className="home-content-quote"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <p>
          We believe every story has the potential to touch hearts and change
          minds. Let's explore yours.
        </p>
      </motion.div>

      <motion.div
        className="parallex-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallexImage} alt="" />
      </motion.div>
    </div>
  );
};

export default CommonContainer;
