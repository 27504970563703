import React from "react";
import ContactContainer from "../../components/ContactContainer";

import NavBar from "../../components/NavBar";

import "./Contact.scss";

import { motion } from "framer-motion";
import ContactCommonContainer from "../../common/ContactCommonContainer";

const Contact = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ backgroundColor: "#00003f" }}
      transition={{ duration: 0.5 }}
    >
      <div className="contact-container">
        <NavBar />
        <ContactContainer />
        <ContactCommonContainer />
      </div>
    </motion.div>
  );
};

export default Contact;
