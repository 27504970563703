/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import ReactAudioPlayer from "react-audio-player";

import videoBg from "../assets/video/hero-video.mp4";
import audioBg from "../assets/audio/bg-sound.mp3";
import backgroundImg from "../assets/video/A2.png";

import "./HomeContainer.scss";

const HomeContainer = () => {
  return (
    <>
      <div className="home-video-container">
        <video
          autoPlay
          loop
          playsInline
          muted
          poster={backgroundImg}
          className="video-background"
        >
          <source src={videoBg} />
          <img src={backgroundImg} alt="" />
        </video>

        <ReactAudioPlayer
          src={audioBg}
          autoPlay={true}
          loop={true}
          volume={0.15}
          style={{ display: "none" }}
        />

        {/* <div className="home-content">
          <motion.div
            className="home-title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.4 }}
          ></motion.div> */}

        {/* <motion.div
          className="parallex-container"
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{ delay: 0.2, duration: 0.4 }}
        >
          <img src={parallexImage} alt="" />
        </motion.div> */}
        <div className="overlay-container"></div>
      </div>
    </>
  );
};

export default HomeContainer;
