import React from "react";

import { motion } from "framer-motion";

import "./WorkCommonContainer.scss";

import parallaxImage from "../assets/parallex/web-for-parallex-1.png";

import workImage from "../assets/parallex/video-image.png";

const WorkCommonContainer = () => {
  return (
    <div className="work-common-container">
      <div className="overlay-container"></div>

      <div className="home-image">
        <img src={workImage} alt="" />
      </div>

      <motion.div
        className="work-youtube-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <iframe
          src="https://youtube.com/embed/DqHL-VGBUC8"
          title="vid3"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
        />
        <iframe
          src="https://www.youtube.com/embed/QSGInbVb3Is"
          title="vid4"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
        />
        <iframe
          src="https://www.youtube.com/embed/bLvO3f294sk"
          title="vid5"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
        />
        <iframe
          src="https://www.youtube.com/embed/LzdCd5HdMlA"
          title="vid6"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
        />
      </motion.div>

      <motion.div
        className="parallax-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallaxImage} alt="" />
      </motion.div>
    </div>
  );
};

export default WorkCommonContainer;
