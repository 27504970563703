import React from "react";
import { motion } from "framer-motion";

import emailjs from "@emailjs/browser";

import videoBg from "../assets/video/hero-video.mp4";

import contactLogo from "../assets/parallex/contact.png";

import backgroundImg from "../assets/video/A2.png";

import "./ContactContainer.scss";

const ContactContainer = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (results) => {
          console.log("SUCCESS!", results.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
    e.target.reset();
  }

  return (
    <div className="contact-video-container">
      <video
        autoPlay
        loop
        playsInline
        muted
        poster={backgroundImg}
        className="video-background"
      >
        <source src={videoBg} type="video/mp4" />
        <img src={backgroundImg} alt="" />
      </video>

      <motion.div
        className="contact-header"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <h2>Contact</h2>
      </motion.div>

      <div className="overlay-container"></div>

      <motion.div
        className="contact-form"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <h1>Contact Us</h1>
        <form onSubmit={sendEmail}>
          <div className="input-box">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
            />
          </div>
          <div className="input-box">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              name="email"
            />
          </div>
          <div className="input-box">
            <label htmlFor="message">Message</label>
            <textarea
              className="textarea-control"
              id="message"
              cols="30"
              rows="8"
              placeholder="Your message"
              name="message"
            ></textarea>
          </div>
          <input type="submit" className="btn" value="Send Message" />
        </form>
      </motion.div>

      {/* <motion.div
        className="social-media-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <p>project@thepsyreel.com</p>
        <span>+91-8340726985</span>
        <div className="social-media">
          <a href="https://www.instagram.com/accounts/login/?next=/thepsyreel/">
            <img src={instagramIcon} alt="instagram" />
          </a>
          <a href="https://www.facebook.com/thepsyreel/">
            <img src={facebookIcon} alt="facebook" />
          </a>
          <a href="https://twitter.com/thepsyreel?t=OU-nRpZfV3hHYqVmPWH8oQ&s=09">
            <img src={twitterIcon} alt="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/the-psy-reel-films/">
            <img src={linkedinIcon} alt="linkedin" />
          </a>
          <a href="https://api.whatsapp.com/message/5G5RKSPR2UUYG1?autoload=1&app_absent=0">
            <img src={whatsappIcon} alt="whatsapp" />
          </a>
        </div>
      </motion.div> */}

      <div className="contact-logo">
        <img src={contactLogo} alt="" />
      </div>

      {/* <motion.div
        className="parallex-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallexImage} alt="" />
      </motion.div> */}
    </div>
  );
};

export default ContactContainer;
