import React from "react";

import { useMediaQuery } from "react-responsive";

import "./NavBar.scss";
import NavLinks from "./NavLinks";
import MobileNavLinks from "./MobileNavLinks";

import psyReelLogo from "../assets/icons/psy-logo.png";
import { Link } from "react-router-dom";

const NavBar = () => {
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  return (
    <div className="navbar-container">
      <div className="navbar-logo">
        <Link to="/">
          <img src={psyReelLogo} alt="" />
        </Link>
      </div>

      <div>
        <div className="desktop-navbar">{!isMobile && <NavLinks />}</div>

        <div className="mobile-navbar">{isMobile && <MobileNavLinks />}</div>
      </div>
    </div>
  );
};

export default NavBar;
