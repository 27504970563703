import React from "react";
import { motion } from "framer-motion";

import "./ServicesContainer.scss";

import videoBg from "../assets/video/hero-video.mp4";
import backgroundImg from "../assets/video/A2.png";

import serviceLogo from "../assets/parallex/services.png";

const ServicesContainer = () => {
  return (
    <div className="services-video-container">
      <video
        autoPlay
        loop
        playsInline
        muted
        poster={backgroundImg}
        className="video-background"
      >
        <source src={videoBg} type="video/mp4" />
        <img src={backgroundImg} alt="" />
      </video>

      <motion.div
        className="service-header"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <h2>Services</h2>
      </motion.div>

      <div className="overlay-container"></div>

      <div className="services-content">
        <div className="service-logo">
          <img src={serviceLogo} alt="" />
        </div>
        <motion.div
          className="service-main-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.4 }}
        >
          <div className="service-content-desc">
            <p>
              From conducting subject-specific research to developing creative
              concepts, shooting visuals, and recording audio, Our work extends
              to creating ad films, campaigns, and documentary projects.
            </p>
          </div>
          <div className="service-provide">
            <div className="ads-film-campaign">
              <h3>Advertisement AV/campaign</h3>
              <p>
                We will provide you with top-notch and professionally creative
                services for developing your company's identity and brand
                emotion with an advertisement AV or a campaign. You can rely on
                us in the ever-changing landscape of advertising. We are
                constantly in transition, with new products making their way to
                the market.
              </p>
            </div>
            <div className="doc-film">
              <h3>Corporate Film</h3>
              <p>
                Corporate films are a great way to present yourself internally
                and externally for better communication that helps people
                understand your work culture and your passion and potential.
                Therefore, someone needs to understand that vision while
                portraying it in audio-visual form. We can create an
                industry-standard company portfolio film, training, instruction,
                and safety videos.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ServicesContainer;
