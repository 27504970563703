import React from "react";

import NavBar from "../../components/NavBar";

import "./Home.scss";
import HomeContainer from "../../components/HomeContainer";

import { motion } from "framer-motion";
import HomeCommonContainer from "../../common/HomeCommonContainer";

const Home = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ backgroundColor: "#00003f" }}
      transition={{ duration: 0.4 }}
    >
      <div className="home-container">
        <NavBar />
        <HomeContainer />
        <HomeCommonContainer />
      </div>
    </motion.div>
  );
};

export default Home;
