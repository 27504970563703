/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

import { motion } from "framer-motion";

import videoBg from "../assets/video/hero-video.mp4";
import backgroundImg from "../assets/video/A2.png";

import psyReelLogo from "../assets/parallex/psd2.png";

import "./WorkContainer.scss";

const WorkContainer = () => {
  return (
    <div className="work-video-container">
      <video
        autoPlay
        loop
        playsInline
        muted
        poster={backgroundImg}
        className="video-background"
      >
        <source src={videoBg} type="video/mp4" />
        <img src={backgroundImg} alt="" />
      </video>

      <motion.div
        className="work-header"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <h2>Works</h2>
      </motion.div>

      <div className="overlay-container"></div>

      <div className="psy-reel-logo">
        <img src={psyReelLogo} alt="" />
      </div>

      <motion.div
        className="youtube-video-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <iframe
          src="https://youtube.com/embed/Fsbzgp07-qo"
          title="vid1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
        />
        <iframe
          src="https://youtube.com/embed/Jt2l7lgtOGc?"
          title="vid2"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen={true}
        />
      </motion.div>

      {/* <motion.div
        className="parallex-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallexImage} alt="" />
      </motion.div> */}
    </div>
  );
};

export default WorkContainer;
