import React from "react";

import NavBar from "../../components/NavBar";

import "./Work.scss";
import WorkContainer from "../../components/WorkContainer";
import WorkCommonContainer from "../../common/WorkCommonContainer";

const Work = () => {
  return (
    <div className="work-container">
      <NavBar />
      <WorkContainer />
      <WorkCommonContainer />
    </div>
  );
};

export default Work;
