import React, { useState } from "react";
import MenuToggle from "./MenuToggle";

import { Link } from "react-router-dom";

import "./MobileNavLinks.scss";

const MobileNavLinks = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="mobile-nav-container">
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen && (
        <ul className="mobile-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/work">Works</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default MobileNavLinks;
