import React from "react";

import NavBar from "../../components/NavBar";
import AboutContainer from "../../components/AboutContainer";

import "./About.scss";

import { motion } from "framer-motion";
import AboutCommonContainer from "../../common/AboutCommonContainer";

const About = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ backgroundColor: "#00003f" }}
      transition={{ duration: 0.5 }}
    >
      <div className="about-container">
        <NavBar />
        <AboutContainer />
        <AboutCommonContainer />
      </div>
    </motion.div>
  );
};

export default About;
