import React from "react";

import { motion } from "framer-motion";

import "./ServiceCommonContainer.scss";

import parallaxImage from "../assets/parallex/web-for-parallex-1.png";

import serviceImage from "../assets/parallex/collage2.png";

const ServiceCommonContainer = () => {
  return (
    <div className="service-common-container">
      <div className="overlay-container"></div>

      <div className="home-image">
        <img src={serviceImage} alt="" />
      </div>

      <motion.div
        className="service-common-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <div className="csr-doc">
          <h3>CSR Documentary</h3>
          <p>
            People want to see the positive impact of investing in your business
            and if your values match theirs. We'll document your philanthropic
            endeavors, give back to the community, and provide positive social
            values in the form of documentary films.
          </p>
        </div>
        <div className="audio-video-prod">
          <h3>Audio-Video Post Production</h3>
          <p>
            In simple terms, post-production refers to all of the tasks that
            occur after filming is completed. We work on editing the visual and
            audio materials, creating music, sound design, and visual effects,
            and delivering them to the final stage of delivery. We can assist
            you in shaping your story to fit your vision.
          </p>
        </div>
        <div className="still-motion">
          <h3>Still & Motion Graphics</h3>
          <p>
            When it comes to a photo project, the decisions you make at the
            beginning of the process will affect the entirety of the project. We
            collaborate with your team to find the best, most cost-effective
            solution for your photography needs and to ensure that your project
            runs as smoothly as possible. Our team specializes in fashion, food,
            travel, fashion, and product photography, as well as animated
            graphics, titles, and company graphic kits for your brand.
          </p>
        </div>
      </motion.div>

      <motion.div
        className="parallax-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallaxImage} alt="" />
      </motion.div>
    </div>
  );
};

export default ServiceCommonContainer;
