import React from "react";

import { motion } from "framer-motion";

import "./AboutCommonContainer.scss";

import parallaxImage from "../assets/parallex/web-for-parallex-1.png";

import aboutImage from "../assets/parallex/collage1.png";

const AboutCommonContainer = () => {
  return (
    <div className="about-common-container">
      <div className="overlay-container"></div>

      <div className="home-image">
        <img src={aboutImage} alt="" />
      </div>

      <motion.div
        className="about-common-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <p>
          We conceive brand campaigns that amalgamate the impact of digital
          space and word-of-mouth marketing, while also creating a unique space
          for brands to register their presence and connect with consumers.
        </p>
        <p>
          We believe brands tell the stories that people get to hear about
          themselves. And while some of these stories excite us, in our opinion,
          there is plenty to discover for the brands and the people. Hence, it
          is essential that we work with our clients as a team, to better
          understand their strengths and vision, and finally deliver the best
          strategy accordingly and execute it.
        </p>
      </motion.div>

      <motion.div
        className="parallax-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallaxImage} alt="" />
      </motion.div>
    </div>
  );
};

export default AboutCommonContainer;
