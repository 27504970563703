import React from "react";

import NavBar from "../../components/NavBar";
import ServicesContainer from "../../components/ServicesContainer";

import { motion } from "framer-motion";

import "./Services.scss";
import ServiceCommonContainer from "../../common/ServiceCommonContainer";

const Services = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      exit={{ backgroundColor: "#00003f" }}
      transition={{ duration: 0.5 }}
    >
      <div className="services-container">
        <NavBar />
        <ServicesContainer />
        <ServiceCommonContainer />
      </div>
    </motion.div>
  );
};

export default Services;
