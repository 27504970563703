import React from "react";

import { motion } from "framer-motion";

import "./ContactCommonContainer.scss";

import parallaxImage from "../assets/parallex/web-for-parallex-1.png";

import contactImage from "../assets/parallex/6.png";
import contactImageTwo from "../assets/parallex/grass.png";

import instagramIcon from "../assets/icons/instagram.png";
import facebookIcon from "../assets/icons/fb.png";
import twitterIcon from "../assets/icons/twitter.png";
import linkedinIcon from "../assets/icons/linkedin.png";
import whatsappIcon from "../assets/icons/whatsapp.png";

const ContactCommonContainer = () => {
  return (
    <div className="contact-common-container">
      <div className="overlay-container"></div>

      <div className="home-image-one">
        <img src={contactImage} alt="" />
      </div>

      <div className="home-image-two">
        <img src={contactImageTwo} alt="" />
      </div>

      <motion.div
        className="contact-social-media"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <p>contact@thepsyreel.com</p>
        <span>+91-8340726985</span>
        <div className="social-media">
          <a href="https://www.instagram.com/accounts/login/?next=/thepsyreel/">
            <img src={instagramIcon} alt="instagram" />
          </a>
          <a href="https://www.facebook.com/thepsyreel/">
            <img src={facebookIcon} alt="facebook" />
          </a>
          <a href="https://twitter.com/thepsyreel?t=OU-nRpZfV3hHYqVmPWH8oQ&s=09">
            <img src={twitterIcon} alt="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/the-psy-reel-films/">
            <img src={linkedinIcon} alt="linkedin" />
          </a>
          <a href="https://api.whatsapp.com/message/5G5RKSPR2UUYG1?autoload=1&app_absent=0">
            <img src={whatsappIcon} alt="whatsapp" />
          </a>
        </div>
      </motion.div>

      <motion.div
        className="map-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <h3>Reach Us</h3>
        <div className="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15078.41760143019!2d72.8315318!3d19.1250032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7475c2be7dd8c0e8!2sThe%20Psy%20Reel!5e0!3m2!1sen!2sin!4v1653111962744!5m2!1sen!2sin"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="psy-reel-map"
          />
        </div>
      </motion.div>

      <motion.div
        className="parallax-container"
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <img src={parallaxImage} alt="" />
      </motion.div>
    </div>
  );
};

export default ContactCommonContainer;
